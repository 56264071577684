import React, { useState } from 'react';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import services from './apiServices';

export default function Login() {
    const [logincreds, setLoginCreds] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState({
        username: '',
        password: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState(''); // New state for success message

    const handleChange = (e) => {
        setLoginCreds({
            ...logincreds,
            [e.target.name]: e.target.value
        });
        setError({
            ...error,
            [e.target.name]: ''
        });
        setSuccessMessage('');
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Validate form inputs
    //     if (logincreds.username === '' && logincreds.password === '') {
    //         setError({
    //             ...error,
    //             username: 'username is required.',
    //             password: 'Password is required',
    //             message: ''
    //         });
    //         return;
    //     }

    //     if (logincreds.username === '') {
    //         setError({
    //             ...error,
    //             username: 'username is required.',
    //             message: ''
    //         });
    //         return;
    //     }

    //     if (logincreds.password === '') {
    //         setError({
    //             ...error,
    //             password: 'Password is required',
    //             message: ''
    //         });
    //         return;
    //     }

    //     try {
    //         const res = await services.sendLoginData(logincreds);
    //         console.log(res); // Handle the response as needed
    //         // Clear errors and handle successful login
    //         setError({
    //             username: '',
    //             password: '',
    //             message: ''
    //         });
    //         // Optionally redirect or update the UI based on successful login
    //         setSuccessMessage('Login successful!'); // Set success message
    //         window.location.replace('/registrationdetails')
    //     } catch (error) {
    //         //Set a generic error message for failed login
    //         setError({
    //             username: '',
    //             password: '',
    //             username: 'Login failed. Please check your credentials and try again.'
    //         });
    //         setSuccessMessage('');
    //     }
    // };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (logincreds.username === '' || logincreds.password === '') {
            setError({
                username: logincreds.username === '' ? 'username is required.' : '',
                password: logincreds.password === '' ? 'Password is required.' : '',
            });
            return;
        }

        try {
            const res = await services.sendLoginData(logincreds);
            const { hasDetails } = res;

            setSuccessMessage('Login successful!');
            setError({ username: '', password: '' });

            // Redirect based on registration details existence
            if (hasDetails) {
                window.location.replace('/Proddashb');
            } else {
                window.location.replace('/registrationdetails');
            }
        } catch (error) {
            setError({
                username: 'Login failed. Please check your credentials and try again.',
                password: '',
            });
            setSuccessMessage('');
        }
    };

    return (

        <section className="login-container">
            <div className="login-header">
                <h1>Welcome</h1>
            </div>
            <div className="login-form-container">
                <div className="login-content">
                    <h4 className="login-title">Seller Login</h4>
                    <form name="frm_login" id="frm_login" onSubmit={handleSubmit} className="login-form">
                        <div className="form-group">
                            <TextField
                                id="Username"
                                className="input-field"
                                label="Username"
                                variant="outlined"
                                size="small"
                                color="warning"
                                name="username"
                                value={logincreds.username}
                                onChange={handleChange}
                                fullWidth
                            />
                            {error.username && <Alert severity="error" className="alert-field">{error.username}</Alert>}
                        </div>
                        <div className="form-group">
                            <TextField
                                id="Password"
                                className="input-field"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                label="Password"
                                variant="outlined"
                                size="small"
                                color="warning"
                                value={logincreds.password}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleTogglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                            {error.password && <Alert severity="error" className="alert-field">{error.password}</Alert>}
                        </div>

                        <div className="form-group">
                            <Button type="submit" id="form-submit" className="login-btn" variant="contained">
                                Login
                            </Button>
                        </div>
                        <div className="form-group">
                            <Link to="/Register" className="register-link">
                                New to SELLERHSBM? Create an account
                            </Link>
                        </div>
                    </form>
                    {error.username === '' && error.password === '' && error.message && <Alert severity="error">{error.message}</Alert>}
                    {successMessage && <Alert severity="success">{successMessage}</Alert>}
                </div>
            </div>
        </section >
    );
}
