import React, { useState } from 'react';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import services from './apiServices';
import '../css/rdbs.css';


export default function Register() {
    const initialFormData = {
        titlecode: '',
        firstName: '',
        middleName: '',
        lastName: '',
        displayName: '',
        birthdate: '',
        gender: '',
        email: '',
        ph1: '',
        ph2: '',
        panno: '',
        username: '',
        password: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState({
        titlecode: '',
        firstName: '',
        middleName: '',
        lastName: '',
        displayName: '',
        birthdate: '',
        gender: '',
        email: '',
        ph1: '',
        ph2: '',
        panno: '',
        username: '',
        password: '',
        message: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [mainName, subName] = name.split('.');

        if (subName) {
            setFormData(prevState => ({
                ...prevState,
                [mainName]: {
                    ...prevState[mainName],
                    [subName]: value
                }
            }));
            setError(prevState => ({
                ...prevState,
                [mainName]: {
                    ...prevState[mainName],
                    [subName]: ''
                }
            }));
        } else {
            setFormData({ ...formData, [name]: value });
            setError({ ...error, [name]: '', message: '' });
            setSuccessMessage('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formValid = true;
        const newErrorState = { ...error };


        // Validate titlecode
        if (formData.titlecode.trim() === '') {
            newErrorState.titlecode = 'Prefix is required';
            formValid = false;
        }

        // Validate First name
        if (formData.firstName.trim() === '') {
            newErrorState.firstName = 'First Name is required';
            formValid = false;
        }

        // Validate last name
        if (formData.lastName.trim() === '') {
            newErrorState.lastName = 'Last Name is required';
            formValid = false;
        }

        // Validate display name
        if (formData.displayName.trim() === '') {
            newErrorState.displayName = 'Display Name is required';
            formValid = false;
        }

        // Validate birthdate
        if (formData.birthdate.trim() === '') {
            newErrorState.birthdate = 'Birthdate is required';
            formValid = false;
        }

        // Validate email
        if (formData.email.trim() === '') {
            newErrorState.email = 'Email is required';
            formValid = false;
        }


        // Validate email
        if (formData.ph1.trim() === '') {
            newErrorState.ph1 = 'Phone Number 1 is required';
            formValid = false;
        }

        // Validate panno: '',
        if (formData.panno.trim() === '') {
            newErrorState.panno = 'Pan Number is required';
            formValid = false;
        }

        // Validate username
        if (formData.username.trim() === '') {
            newErrorState.username = 'Username is required';
            formValid = false;
        }

        // Validate password
        if (formData.password.trim() === '') {
            newErrorState.password = 'Password is required';
            formValid = false;
        }

        // Validate gender
        if (formData.gender === '') {
            newErrorState.gender = 'Please select your gender';
            formValid = false;
        }

        // If form is not valid, update error state and prevent submission
        if (!formValid) {
            setError(newErrorState);
            return;
        }

        // If form is valid, proceed with registration
        try {
            const res = await services.sendRegistrationData(formData);
            console.log(res); // Handle the response as needed

            // Optionally, reset the form after successful submission
            setFormData(initialFormData);
            setError({
                titlecode: '',
                firstName: '',
                middleName: '',
                lastName: '',
                displayName: '',
                birthdate: '',
                gender: '',
                email: '',
                ph1: '',
                ph2: '',
                panno: '',
                username: '',
                password: '',
                message: ''
            });
            setSuccessMessage('Registration successful!');
        } catch (error) {
            setError({ ...error, message: `Failed to register: ${error.message}` });
            setSuccessMessage('');
        }
    };

    return (

        <div className="cd-full-width first-slide cd-hero-form">
            <div className="container">
                <div className="row row-custom">
                    <div className="col-md-12 row-custom">
                        <div className="content login-content login-custom">
                            <h2 className='custom-label-login'>Seller Register</h2>
                            <form name="frm_register" id="frm_register" className="form-custom" onSubmit={handleSubmit}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>titlecode:</label> */}
                                            <TextField
                                                fullWidth
                                                name="titlecode"
                                                label="Prefix"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.titlecode}
                                                onChange={handleChange}
                                            />
                                            {error.titlecode && <Alert severity="error">{error.titlecode}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>First Name:</label> */}
                                            <TextField
                                                fullWidth
                                                name="firstName"
                                                label="First Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                            />
                                            {error.firstName && <Alert severity="error">{error.firstName}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Middle Name:</label> */}
                                            <TextField
                                                fullWidth
                                                name="middleName"
                                                label="Middle Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.middleName}
                                                onChange={handleChange}
                                            />
                                            {error.middleName && <Alert severity="error">{error.middleName}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Last Name:</label> */}
                                            <TextField
                                                fullWidth
                                                name="lastName"
                                                label="Last Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                            />
                                            {error.lastName && <Alert severity="error">{error.lastName}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Display Name:</label> */}
                                            <TextField
                                                fullWidth
                                                name="displayName"
                                                label="Display Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.displayName}
                                                onChange={handleChange}
                                            />
                                            {error.displayName && <Alert severity="error">{error.displayName}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Birthdate:</label> */}
                                            <TextField
                                                fullWidth
                                                name="birthdate"
                                                label="Birthdate"
                                                type="date"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                InputLabelProps={{ shrink: true }}
                                                value={formData.birthdate}
                                                onChange={handleChange}
                                            />
                                            {error.birthdate && <Alert severity="error">{error.birthdate}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Gender:</label> */}
                                            <RadioGroup
                                                row
                                                name="gender"
                                                value={formData.gender}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                            </RadioGroup>
                                            {error.gender && <Alert severity="error">{error.gender}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Email:</label> */}
                                            <TextField
                                                fullWidth
                                                name="email"
                                                label="Email"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {error.email && <Alert severity="error">{error.email}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {/* <label>ph1:</label> */}
                                            <TextField
                                                fullWidth
                                                name="ph1"
                                                label="Phone Number 1"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.ph1}
                                                onChange={handleChange}
                                            />
                                            {error.ph1 && <Alert severity="error">{error.ph1}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>ph2:</label> */}
                                            <TextField
                                                fullWidth
                                                name="ph2"
                                                label="Phone Number 2"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.ph2}
                                                onChange={handleChange}
                                            />
                                            {error.ph2 && <Alert severity="error">{error.ph2}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {/* <label>panno:</label> */}
                                            <TextField
                                                fullWidth
                                                name="panno"
                                                label="Pan Number"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.panno}
                                                onChange={handleChange}
                                            />
                                            {error.panno && <Alert severity="error">{error.panno}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Username:</label> */}
                                            <TextField
                                                fullWidth
                                                name="username"
                                                label="Username"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.username}
                                                onChange={handleChange}
                                            />
                                            {error.username && <Alert severity="error">{error.username}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            {/* <label>Password:</label> */}
                                            <TextField
                                                fullWidth
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                label="Password"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.password}
                                                onChange={handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={togglePasswordVisibility}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {error.password && <Alert severity="error">{error.password}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="primary-button custom-button">
                                                <Button variant="contained" color="primary" size="large" className="custom-btn" type="submit">
                                                    Register
                                                </Button>
                                            </div>
                                            <br></br>
                                            <div className="loginlink">
                                                <Link to="/Login" className="login-link">Existing User? Log in</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            {/* Display overall registration error */}
                            {error.message && <Alert severity="error">{error.message}</Alert>}
                            {/* Display success message */}
                            {successMessage && <Alert severity="success">{successMessage}</Alert>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
