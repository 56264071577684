import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Container,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import services from './apiServices';

export default function Proddashb() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch products from backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await services.getproddash();
                setProducts(data.products);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <Container maxWidth="lg" className="product-dashboard-container">
            <Typography variant="h4" align="center" gutterBottom>
                Product Dashboard
            </Typography>
            <br />

            {loading ? (
                <CircularProgress className="centered-loader" />
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <Typography variant="h6">SR</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Product Category</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Product Type</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Product Name</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Product Price</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Product UOM</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((product, index) => (
                                <TableRow key={product.PROD_ID}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{product.PROD_CAT_ID}</TableCell>
                                    <TableCell align="center">{product.PROD_TYP_ID}</TableCell>
                                    <TableCell align="center">{product.PROD_DESP}</TableCell>
                                    <TableCell align="center">Rs {product.PROD_PRICE}</TableCell>
                                    <TableCell align="center">{product.PROD_UOM}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Add Products Button */}
            <Box className="button-container">
                <Link to="/Productaddition" className="no-text-decoration">
                    <Button variant="contained" color="primary" size="large" className="add-product-button">
                        Add Products
                    </Button>
                </Link>
            </Box>
            <br />
        </Container>
    );
}
