import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { Typography } from "@mui/material";
import services from './apiServices';
import '../css/rdbs.css';

export default function Productaddition() {
    const fileInputRef = useRef(null);
    const navigate = useNavigate(); // Initialize useNavigate
    const initialFormData = {
        prodfullnm: '', prodshortnm: '', proddispnm: '', prodprice: '', priceqty: '', produom: '', prodcatid: '', prodtypeid: '', prodcategory: '', prodtype: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const uomOptions = [
        'Kg', 'Kgs', 'Gms', 'Ltr', 'ML',
        'Pc', 'Pcs', 'Dz', 'No', 'Nos', 'GST', 'VAT'
    ];

    // Fetch categories on mount
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await services.getCategories();
                if (response?.success) {
                    setCategories(response.categories);
                } else {
                    console.error('Failed to fetch categories:', response.message);
                }
            } catch (error) {
                console.error('Error fetching categories:', error.message);
            }
        };
        fetchCategories();
    }, []);

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         console.log("Selected file:", file.name);
    //         // Perform file upload processing here
    //     }
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name); // Store file name in state
            console.log("Selected file:", file.name);
        }
    };


    // Fetch types when category changes
    const handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value;
        setFormData({ ...formData, prodcategory: selectedCategory, prodcatid: selectedCategory });
        try {
            const data = await services.getTypes(selectedCategory);
            setTypes(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching types:', error);
            setTypes([]);
        }
    };

    // Handle input field changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    // Field labels mapping
    const fieldLabels = {
        prodcategory: 'Product Category', prodtype: 'Product Type', prodfullnm: 'Product Full Name', prodshortnm: 'Product Short Name', proddispnm: 'Product Display Name', prodprice: 'Product Price', priceqty: 'Product Price Quantity', produom: 'Product UOM', prodcatid: 'Product Category', prodtypeid: 'Product Type',
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formValid = true;
        const newErrorState = { ...error };

        // Required fields list (exclude optional fields)
        const requiredFields = ["prodcategory", "prodtype", "prodfullnm", "prodshortnm", "proddispnm",
            "prodprice", "priceqty", "produom", "prodcatid", "prodtypeid",
        ];
        // Validate only required fields
        requiredFields.forEach((key) => {
            if (!formData[key]?.trim()) {
                newErrorState[key] = `${fieldLabels[key]} is required`; // Use label instead of variable name
                formValid = false;
            }
        });

        setError(newErrorState);
        if (!formValid) return;

        // Create FormData object to send data and file
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        // Append the file to the FormData object
        const file = fileInputRef.current.files[0];
        if (file) {
            formDataToSend.append('prodImage', file);
        }

        try {
            const res = await services.sendproductData(formDataToSend);
            console.log(res);
            setFormData(initialFormData);
            setError({});
            setSuccessMessage('Product added successfully!');
            // Navigate to Proddashb page after successful submission
            setTimeout(() => navigate('/Proddashb'), 1000); // Add a delay to display success message
        } catch (error) {
            setError({ ...error, message: `Failed to add product: ${error.message}` });
            setSuccessMessage('');
        }
    };

    return (
        <div className="cd-hero-form">
            <div className="addition">
                <div className="row row-custom">
                    <div className="col-md-12 row-custom">
                        <div className="content login-content login-custom">
                            <h4 className="custom-label-login">Product Addition</h4>
                            <form name="frm_register" id="frm_register" className="form-custom" onSubmit={handleSubmit}>
                                <div className="formdata">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <FormControl fullWidth size="small">
                                                <InputLabel>Product Category</InputLabel>
                                                <Select
                                                    name="prodcategory"
                                                    value={formData.prodcategory}
                                                    onChange={handleCategoryChange}
                                                    label="Product Category"
                                                    error={!!error.prodcategory}
                                                >
                                                    {categories.map((category) => (
                                                        <MenuItem key={category.ATTRIB_CD} value={category.ATTRIB_CD}>
                                                            {category.ATTRIB_NAME}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {error.prodcategory && <Alert severity="error">{error.prodcategory}</Alert>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <FormControl fullWidth size="small">
                                                <InputLabel>Product Type</InputLabel>
                                                <Select
                                                    name="prodtype"
                                                    value={formData.prodtype}
                                                    onChange={(e) =>
                                                        handleInputChange({ target: { name: "prodtype", value: e.target.value } })
                                                    }
                                                    label="Product Type"
                                                    error={!!error.prodtype}
                                                >
                                                    {types.map((type) => (
                                                        <MenuItem key={type.ATTRIB_CD} value={type.ATTRIB_CD}>
                                                            {type.ATTRIB_NAME}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {error.prodtype && <Alert severity="error">{error.prodtype}</Alert>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                fullWidth
                                                name="prodfullnm"
                                                label="Product Full Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.prodfullnm}
                                                onChange={handleInputChange}
                                                error={!!error.prodfullnm}
                                                helperText={error.prodfullnm}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                fullWidth
                                                name="prodshortnm"
                                                label="Product Short Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.prodshortnm}
                                                onChange={handleInputChange}
                                                error={!!error.prodshortnm}
                                                helperText={error.prodshortnm}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                fullWidth
                                                name="proddispnm"
                                                label="Product Display Name"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.proddispnm}
                                                onChange={handleInputChange}
                                                error={!!error.proddispnm}
                                                helperText={error.proddispnm}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                fullWidth
                                                name="prodprice"
                                                label="Product Price"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.prodprice}
                                                onChange={handleInputChange}
                                                error={!!error.prodprice}
                                                helperText={error.prodprice}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <TextField
                                                fullWidth
                                                name="priceqty"
                                                label="Product Price Quantity"
                                                variant="outlined"
                                                size="small"
                                                color="warning"
                                                value={formData.priceqty}
                                                onChange={handleInputChange}
                                                error={!!error.priceqty}
                                                helperText={error.priceqty}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <FormControl fullWidth size="small">
                                                <InputLabel>Product UOM</InputLabel>
                                                <Select
                                                    name="produom"
                                                    value={formData.produom}
                                                    onChange={handleInputChange}
                                                    label="Product UOM"
                                                    error={!!error.produom}
                                                >
                                                    {uomOptions.map((uom, index) => (
                                                        <MenuItem key={index} value={uom}>
                                                            {uom}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <Typography variant="body2" color="error">
                                        * Image dimensions must be 400px × 400px; otherwise, the image will not be displayed on HSBM.
                                    </Typography>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input
                                                type="file"
                                                name="prodImage"
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                className="file-input"
                                                accept=".pdf,.doc,.docx,.jpg,.png,.xls,.xlsx"
                                            />
                                            {selectedFileName && (
                                                <p className="selected-file-text">
                                                    Selected File: {selectedFileName}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="primary-button custom-button">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    id="form-submit"
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {error.message && <Alert severity="error">{error.message}</Alert>}
                            {successMessage && <Alert severity="success">{successMessage}</Alert>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
