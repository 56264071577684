import React from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { FaChartLine, FaBoxOpen, FaHandshake } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link for routing

export default function Home() {
  return (
    <Container>
      {/* Hero Section */}
      <section className="hero-section mt-4">
        <Box>
          <Typography variant="h3" className="hero-title">
            Start Selling on HSBM Today!
          </Typography>
          <Typography variant="h6" className="hero-subtitle">
            Reach millions of customers and grow your business effortlessly.
          </Typography>
          <Box className="hero-button-container">
            <Link to="/Login" className="hero-link">
              <Button variant="contained" color="primary" size="large" className="hero-button">
                Start Selling
              </Button>
            </Link>
          </Box>
        </Box>
      </section>

      {/* Steps to Get Started */}
      <section className="steps-section mt-4">
        <Typography variant="h4" align="center" gutterBottom>
          How to Get Started
        </Typography>
        <Grid container spacing={4} className="steps-container">
          {[
            { icon: <FaBoxOpen size={50} className="step-icon" />, title: "Step 1: Sign Up", description: "Create your seller account with ease." },
            { icon: <FaChartLine size={50} className="step-icon" />, title: "Step 2: List Products", description: "Add your products with attractive images and details." },
            { icon: <FaHandshake size={50} className="step-icon" />, title: "Step 3: Start Selling", description: "Start receiving orders and grow your business." },
          ].map((step, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card className="step-card">
                {step.icon}
                <Typography variant="h6" className="step-title">
                  {step.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" className="step-description">
                  {step.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section mt-4">
        <Typography variant="h4" align="center" className="section-title">
          Why Sell on HSBM?
        </Typography>
        <Grid container spacing={4} className="benefits-container">
          {[
            { image: "https://via.placeholder.com/600x300", title: "Wide Reach", description: "Access a large customer base and boost your sales." },
            { image: "https://via.placeholder.com/600x300", title: "Easy Management", description: "Use advanced tools to track orders and manage inventory." },
          ].map((benefit, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card className="benefit-card">
                <CardMedia component="img" height="200" image={benefit.image} alt={benefit.title} className="benefit-image" />
                <CardContent>
                  <Typography variant="h6">{benefit.title}</Typography>
                  <Typography variant="body2" color="textSecondary" className="benefit-description">
                    {benefit.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </section>

      {/* FAQ Section */}
      <section className="faq-section mt-4">
        <Typography variant="h4" align="center" className="section-title">
          Frequently Asked Questions
        </Typography>
        <Box className="faq-container">
          {[
            { question: "How do I register as a seller?", answer: "Click the 'Register Now' button above and follow the simple steps." },
            { question: "Are there any fees for listing products?", answer: "There are minimal charges for premium listings; basic listings are free." },
          ].map((faq, index) => (
            <Box key={index} className="faq-item">
              <Typography variant="h6">{faq.question}</Typography>
              <Typography variant="body2" color="textSecondary" className="faq-answer">
                {faq.answer}
              </Typography>
            </Box>
          ))}
        </Box>
      </section>

      {/* Footer */}
      <section className="footer-section mt-4">
        <Box className="footer">
          <Typography variant="body2" color="textSecondary">
            © 2024 HSBM. All Rights Reserved.
          </Typography>
        </Box>
      </section>
    </Container>
  );
}
