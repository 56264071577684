import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Navheader from './Component/Navheader';
import Navbar from './Component/Navbar';
import Home from './Component/home'; // Correct import path
import Login from './Component/Login';
import Register from './Component/Register';
import Footer from './Component/Footer';
import RegistrationDetails from './Component/registrationdetails'; 
import Productaddition from './Component/Productaddition'
import Proddashb from './Component/Proddashb'
import Userprofile from './Component/Userprofile'


import './css/bootstrap.min.css'
import './css/rdbs.css'
import './css/templatemo.css'
import './css/templatemo.min.css'


function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/Login" element={<Login />} />
        </Routes>
        <Routes>
          <Route path="/Register" element={<Register/>}/>
        </Routes>
        <Routes>
          <Route path="/RegistrationDetails" element={<RegistrationDetails/>}/>
        </Routes>
        <Routes>
          <Route path="/Productaddition" element={<Productaddition/>}/>
        </Routes>
        <Routes>
          <Route path="/Proddashb" element={<Proddashb/>}/>
        </Routes>
        <Routes>
          <Route path="/Userprofile" element={<Userprofile/>}/>
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
