import React, { useState, useEffect } from 'react';
import Logo from '../img/RDBS imgs/logo.png';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCartArrowDown, faUser } from '@fortawesome/free-solid-svg-icons';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { logout } from './Logoutuser';
import axios from 'axios';

export default function Navheader() {
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [activetab, setactivetab] = useState();
  const [userName, setUserName] = useState({ firstName: '', lastName: '' });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await axios.get('/api/user/session');
        setUserName({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
        });
      } catch (error) {
        console.error('Error fetching user name:', error);
        setUserName({ firstName: '', lastName: '' });
      }
    };

    fetchUserName();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      setLogoutSuccess(true);
      setTimeout(() => {
        setLogoutSuccess(false);
        window.location.href = '/';
      }, 3000);
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  return (
    // <div>
    //   {logoutSuccess && (
    //     <div className="alert alert-success text-center" role="alert">
    //       Logout successful!
    //     </div>
    //   )}
    //   <nav className="navbar navbar-expand-lg navbar-light shadow fixed-top custom-navbar">
    //     <div className="container d-flex justify-content-between align-items-center">
    //       <a className="navbar-brand text-success logo h1 align-self-center" href="index.html">
    //         <img className="r-logo" src={Logo} alt="" />
    //       </a>

    //       <button
    //         className="navbar-toggler border-0"
    //         type="button"
    //         data-bs-toggle="collapse"
    //         data-bs-target="#templatemo_main_nav"
    //         aria-controls="navbarSupportedContent"
    //         aria-expanded="false"
    //         aria-label="Toggle navigation"
    //       >
    //         <span className="navbar-toggler-icon"></span>
    //       </button>

    //       <div
    //         className="align-self-center collapse navbar-collapse flex-fill d-lg-flex justify-content-lg-between"
    //         id="templatemo_main_nav"
    //       >
    //         <div className="flex-fill">
    //           <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
    //             <li className="nav-item">
    //               <NavLink className="nav-link" to="/">
    //                 <div className={`tab-button ${activetab === 0 ? "active" : ""}`} onClick={() => setactivetab(0)}></div>
    //                 Home
    //               </NavLink>
    //             </li>
    //             <li className="nav-item">
    //               <NavLink className="nav-link" to="/contact">
    //                 <div className={`tab-button ${activetab === 2 ? "active" : ""}`} onClick={() => setactivetab(2)}></div>
    //                 Contact
    //               </NavLink>
    //             </li>
    //             <li className="nav-item">
    //               <NavLink className="nav-link" to="/about">
    //                 <div className={`tab-button ${activetab === 3 ? "active" : ""}`} onClick={() => setactivetab(3)}></div>
    //                 About
    //               </NavLink>
    //             </li>
    //           </ul>
    //         </div>
    //         <div className="navbar align-self-center d-flex">
    //           {userName.firstName && <span className="navbar-text">Hi, {userName.firstName}</span>}
    //           <div
    //             className="nav-icon position-relative text-decoration-none dropdown-container"
    //             onMouseEnter={() => setIsDropdownOpen(true)}
    //             onMouseLeave={() => setIsDropdownOpen(false)}
    //           >
    //             <FontAwesomeIcon icon={faUser} className="text-dark mr-3" />
    //             {isDropdownOpen && (
    //               <div className="dropdown-menu show">
    //                 {userName.firstName ? (
    //                   <>
    //                     <NavLink className="dropdown-item profile-link" to="/Userprofile">
    //                       Profile
    //                     </NavLink>
    //                     <NavLink className="dropdown-item dashboard-link" to="/Proddashb">
    //                       Product Dashboard
    //                     </NavLink>
    //                     <button className="dropdown-item logout-button" onClick={handleLogout}>
    //                       Logout
    //                     </button>
    //                   </>
    //                 ) : null}
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
    <nav className="navbar navbar-expand-lg navbar-light navbar-custom">
      <div className="container navbar-container">
        {/* Logo */}
        <NavLink className="navbar-brand logo h1" to="/">
          <img className="r-logo" src={Logo} alt="Logo" />
        </NavLink>

        {/* Mobile Menu Button */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          className="navbar-toggler"
        >
          <MenuIcon />
        </IconButton>

        {/* Mobile Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          className="mobile-menu"
        >
          <MenuItem onClick={handleMenuClose}>
            <NavLink className="nav-link" to="/">Home</NavLink>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <NavLink className="nav-link" to="/contact">Contact</NavLink>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <NavLink className="nav-link" to="/about">About</NavLink>
          </MenuItem>

          {/* Show username if logged in (Mobile) */}
          {userName.firstName && (
            <MenuItem className="navbar-text">Hi, {userName.firstName}</MenuItem>
          )}

          {/* User Dropdown in Mobile View */}
          {userName.firstName && (
            <MenuItem>
              <div
                className="dropdown"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                {isDropdownOpen && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/Userprofile">Profile</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Proddashb">Dashboard</NavLink></li>
                    <li><button className="dropdown-item" onClick={handleLogout}>Logout</button></li>
                  </ul>
                )}
              </div>
            </MenuItem>
          )}
        </Menu>

        {/* Desktop Navbar */}
        <div className="collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">Contact</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">About</NavLink>
            </li>

            {/* Show username if logged in (Desktop) */}
            {userName.firstName && (
              <li className="nav-item navbar-text">Hi, {userName.firstName}</li>
            )}

            {/* User Dropdown in Desktop View */}
            {userName.firstName && (
              <li
                className="nav-item dropdown"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <NavLink className="nav-link dropdown-toggle" to="#" role="button">
                  <FontAwesomeIcon icon={faUser} className="user-icon" />
                </NavLink>
                {isDropdownOpen && (
                  <ul className="dropdown-menu show">
                    <li><NavLink className="dropdown-item" to="/Userprofile">Profile</NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Proddashb">Dashboard</NavLink></li>
                    <li><button className="dropdown-item" onClick={handleLogout}>Logout</button></li>
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>

  );
}
