import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import services from './apiServices';
import { Link } from 'react-router-dom';
import '../css/rdbs.css';

export default function RegistrationDetails() {
    const steps = ['Seller account creation', 'Business information', 'Conformation'];
    const [currentStep, setCurrentStep] = useState(0);

    const initialFormData = {
        VNDR_CO_F_NM: '',
        VNDR_CO_S_NM: '',
        VNDR_BNK_ACC_NO: '',
        VNDR_BNK_IFSC_CD: '',
        VNDR_BNK_NM: '',
        VNDR_BNK_BRNCH_NM: '',
        VNDR_CONT_PRSON_POST: '',
        VNDR_GST_NO: '',
        buzownAddress1: '',
        buzownAddress2: '',
        buzownAddress3: '',
        buzownAddresscity: '',
        buzownAddresspin: '',
        buzAddress1: '',
        buzAddress2: '',
        buzAddress3: '',
        buzAddresscity: '',
        buzAddresspin: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [error, setError] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError({ ...error, [name]: '' });
    };

    // Field labels mapping
    const fieldLabels = {
        VNDR_CO_F_NM: 'Company Full Name',
        VNDR_CO_S_NM: 'Company Short Name',
        VNDR_BNK_ACC_NO: 'Bank Account Number',
        VNDR_BNK_IFSC_CD: 'Bank IFSC Code',
        VNDR_BNK_NM: 'Bank Name',
        VNDR_BNK_BRNCH_NM: 'Bank Branch Name',
        VNDR_CONT_PRSON_POST: 'Contact Person Name',
        VNDR_GST_NO: 'GST Number',
        buzownAddress1: 'Business Owner Address 1',
        buzownAddress2: 'Business Owner Address 2',
        buzownAddress3: 'Business Owner Address 3',
        buzownAddresscity: 'Business Owner Address City',
        buzownAddresspin: 'Business Owner Address Pin',
        buzAddress1: 'Business Address 1',
        buzAddress2: 'Business Address 2',
        buzAddress3: 'Business Address 3',
        buzAddresscity: 'Business Address City',
        buzAddresspin: 'Business Address Pin',
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrorState = {};
        let formValid = true;

        // Required fields list (exclude optional fields)
        const requiredFields = [
            "VNDR_BNK_ACC_NO",
            "VNDR_BNK_IFSC_CD",
            "VNDR_BNK_NM",
            "VNDR_BNK_BRNCH_NM",
            "buzownAddress1",
            "buzownAddress2",
            "buzownAddress3",
            "buzownAddresscity",
            "buzownAddresspin",
            "buzAddress1",
            "buzAddress2",
            "buzAddress3",
            "buzAddresscity",
            "buzAddresspin",
        ];

        // Validate only required fields
        requiredFields.forEach((key) => {
            if (!formData[key]?.trim()) {
                newErrorState[key] = `${fieldLabels[key]} is required`; // Use label instead of variable name
                formValid = false;
            }
        });

        setError(newErrorState);

        if (!formValid) return;

        try {
            // Send data to the backend
            const response = await services.sendBusinessData(formData);
            console.log(response);

            setSuccessMessage('Business information submitted successfully!');
            setTimeout(() => {
                setSuccessMessage('');
                handleNext(); // Navigate to the next step after success
            }, 1000);
        } catch (err) {
            setError({ ...error, message: `Failed to submit: ${err.response?.data?.message || err.message}` });
        }
    };


    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (

                    <div className="row">
                        <div className="col-md-12">
                            <div className="primary-button custom-button button-container">
                                <Typography variant="h6">
                                    Welcome! Your account has been created. Proceed to the next step.
                                </Typography>
                                <br />
                            </div>
                            <div className="primary-button custom-button button-container">
                                <Button variant="contained" color="primary" size="large" className="next-button" onClick={handleNext}>
                                    Next
                                </Button>
                            </div>
                            <br />
                        </div>
                    </div>

                );
            case 1:
                return (
                    <div className="cd-full-width first-slide cd-hero-form">
                        <div className="details">
                            <div className="row row-custom">
                                <div className="col-md-12 row-custom">
                                    <div className="content login-content login-custom">
                                        <form onSubmit={handleSubmit} className="form-custom">
                                            <Box sx={{ display: 'grid', gap: 2 }}>
                                                {/* Two text fields in one row */}
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_CO_F_NM"
                                                            label="Company Full Name"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_CO_F_NM}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_CO_S_NM"
                                                            label="Company Short Name"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_CO_S_NM}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                                {/* Textarea for multi-line input */}
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_BNK_ACC_NO"
                                                            label="Bank Account Number"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_BNK_ACC_NO}
                                                            onChange={handleChange}
                                                            error={!!error.VNDR_BNK_ACC_NO}
                                                            helperText={error.VNDR_BNK_ACC_NO}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_BNK_IFSC_CD"
                                                            label="Bank IFSC Code"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_BNK_IFSC_CD}
                                                            onChange={handleChange}
                                                            error={!!error.VNDR_BNK_IFSC_CD}
                                                            helperText={error.VNDR_BNK_IFSC_CD}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                                {/* Continue with other form fields */}
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_BNK_NM"
                                                            label="Bank Name"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_BNK_NM}
                                                            onChange={handleChange}
                                                            error={!!error.VNDR_BNK_NM}
                                                            helperText={error.VNDR_BNK_NM}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_BNK_BRNCH_NM"
                                                            label="Bank Branch Name"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_BNK_BRNCH_NM}
                                                            onChange={handleChange}
                                                            error={!!error.VNDR_BNK_BRNCH_NM}
                                                            helperText={error.VNDR_BNK_BRNCH_NM}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_CONT_PRSON_POST"
                                                            label="Contact Person Post"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_CONT_PRSON_POST}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="VNDR_GST_NO"
                                                            label="GST Number"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.VNDR_GST_NO}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="buzownAddress1"
                                                            label="Business Owner Address 1"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzownAddress1}
                                                            onChange={handleChange}
                                                            error={!!error.buzownAddress1}
                                                            helperText={error.buzownAddress1}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzownAddress2"
                                                            label="Business Owner Address 2"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzownAddress2}
                                                            onChange={handleChange}
                                                            error={!!error.buzownAddress2}
                                                            helperText={error.buzownAddress2}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzownAddress3"
                                                            label="Business Owner Address 3"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzownAddress3}
                                                            onChange={handleChange}
                                                            error={!!error.buzownAddress3}
                                                            helperText={error.buzownAddress3}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzownAddresscity"
                                                            label="Business Owner Address City"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzownAddresscity}
                                                            onChange={handleChange}
                                                            error={!!error.buzownAddresscity}
                                                            helperText={error.buzownAddresscity}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzownAddresspin"
                                                            label="Business Owner Address Pin"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzownAddresspin}
                                                            onChange={handleChange}
                                                            error={!!error.buzownAddresspin}
                                                            helperText={error.buzownAddresspin}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            name="buzAddress1"
                                                            label="Business Address 1"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzAddress1}
                                                            onChange={handleChange}
                                                            error={!!error.buzAddress1}
                                                            helperText={error.buzAddress1}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzAddress2"
                                                            label="Business Address 2"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzAddress2}
                                                            onChange={handleChange}
                                                            error={!!error.buzAddress2}
                                                            helperText={error.buzAddress2}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzAddress3"
                                                            label="Business Address 3"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzAddress3}
                                                            onChange={handleChange}
                                                            error={!!error.buzAddress3}
                                                            helperText={error.buzAddress3}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzAddresscity"
                                                            label="Business Address City"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzAddresscity}
                                                            onChange={handleChange}
                                                            error={!!error.buzAddresscity}
                                                            helperText={error.buzAddresscity}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                        <TextField
                                                            name="buzAddresspin"
                                                            label="Business Address Pin"
                                                            variant="outlined"
                                                            size="small"
                                                            value={formData.buzAddresspin}
                                                            onChange={handleChange}
                                                            error={!!error.buzAddresspin}
                                                            helperText={error.buzAddresspin}
                                                            fullWidth
                                                            className="form-field"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Continue with more fields if needed */}
                                            </Box>
                                            {error.message && <Alert severity="error">{error.message}</Alert>}
                                            {successMessage && <Alert severity="success">{successMessage}</Alert>}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="primary-button custom-button">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            sx={{
                                                                transition: "all 0.3s ease", // Added smooth hover effect
                                                                "&:hover": {
                                                                    backgroundColor: "#ff5722", // Color change on hover
                                                                    transform: "scale(1.1)", // Button scales on hover
                                                                },
                                                            }}
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <Box textAlign="center">
                        <Typography variant="h6">Thank you! Your registration is complete.</Typography>
                        <Typography variant="h6">Thank you! Proceed to the Product Addition page For Sell your Product.</Typography>
                        <Link to="/Productaddition" style={{ textDecoration: "none" }}>Go to Product Addition</Link>
                    </Box>
                );
            default:
                return null;
        }
    };
    return (
        <Box sx={{ mt: 3 }}>
            <Stepper activeStep={currentStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {getStepContent(currentStep)}
        </Box>
    );
}
